.design {
	background: #6434f8;
	border-radius: 0% 53% 51% 0% / 20% 14% 15% 23%;
}

.design:before {
	content: '';
	position: absolute;
	top: -100px;
	left: 0;
	height: 100px;
	width: 40px;
	border-bottom-left-radius: 60%;
	background-color: white;
}

.design:before {
	box-shadow: 0 50px 0 0 #6434f8;
}

.design:after {
	content: '';
	position: absolute;
	bottom: -100px;
	left: 0;
	height: 100px;
	width: 40px;
	border-top-left-radius: 60%;
	background-color: white;
}

.design:after {
	box-shadow: 0 -50px 0 0 #6434f8;
}
